import { ICourseService } from "../../../core/services/course";
import { injectable } from "inversify";
import databaseInstance from "../../index";

@injectable()
export class CourseService implements ICourseService {
  private dbInstance: any = databaseInstance;

  private static instance: CourseService;

  constructor() {
    CourseService.instance = this;
  }

  public static getInstance() {
    if (!CourseService.instance) {
      CourseService.instance = new CourseService();
    }
    return CourseService.instance;
  }

  public markAsIncomplete: (
    courseId: number,
    contentId: number
  ) => Promise<void> = (courseId, contentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/courses/${courseId}/contents/${contentId}/complete`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public markAsComplete: (
    courseId: number,
    contentId: number
  ) => Promise<void> = (courseId, contentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/contents/${contentId}/complete`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public shareContentPost: (
    contentId: number,
    postObject: any
  ) => Promise<void> = (contentId, postObject) => {
    return new Promise<void>((resolve, reject) => {
      return this.dbInstance
        .post(`/contents/${contentId}/posts`, postObject)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  };

  public getContentPost: (contentId: number, query?: string) => Promise<void> = (contentId, query = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/course-contents/${contentId}/posts?sort=published_at:desc&include=comments&include=postable${query}`
        )
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public getPinPostCourse: (contentId: number, query?: string) => Promise<void> = (contentId, query = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`postables/${contentId}/posts/pinned?postable_type=course-content&include=comments&include=postable${query}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public pinPostCourse: (contentId: number, postId: number) => Promise<void> = (
    contentId,
    postId
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`postables/${contentId}/posts/${postId}/pinned`, {
          postable_type: 'course-content',
        })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public unpinPostCourse: (
    contentId: number,
    postId: number
  ) => Promise<void> = (contentId, postId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`postables/${contentId}/posts/${postId}/pinned`, {
          data: {
            postable_type: 'course-content',
          }
        })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public startCourse: (courseId: number) => Promise<void> = courseId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/enrolments`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public enrolUser: (courseId: number, userId: number) => Promise<void> = (courseId, userId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/users/${userId}/enroll`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public getCourseEnrolments: (courseId: number, query?: string, page?: number) => Promise<void> = (courseId, query, page) => {
    return new Promise<void>((resolve, reject) => {

      let url = `/courses/${courseId}/enrolments?nsearch=${query}`

      if (page !== undefined) {
        url += `&page=${page}`;
      }

      this.dbInstance
        .get(url)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public getCourseAnalyticsEnrolments: (groupID: number, courseId: number, percentile?: number, page?: number)
    => Promise<void> = (groupID, courseId, percentile, page) => {
      return new Promise<void>((resolve, reject) => {
        let url = `groups/${groupID}/analytics/courses/${courseId}/percentile-enrolments`;

        // Check if percentile is provided, and if so, add it to the URL
        if (percentile !== undefined) {
          url += `?range=${percentile}`;
        }

        if (page !== undefined) {
          url += `&page=${page}`;
        }

        this.dbInstance
          .get(url)
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            reject(error)
            new Error("Something went wrong!")
          });
      });
    };


  public downloadCourseEnrolments: (courseId: number) => Promise<void> = courseId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/enrollments/export`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public downloadCourseAuthorizedUsers: (groupId: number, courseId: number) => Promise<void> = (groupId, courseId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/library-contents/${courseId}/authorized-users/export`, {
          content_type: 'course',
        })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public getCourseEnrolment: (courseId: number, enrolmentId: number) => Promise<void> = (courseId, enrolmentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/courses/${courseId}/enrolments/${enrolmentId}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public removeCourseEnrolment: (courseId: number, enrolmentId: number) => Promise<void> = (courseId, enrolmentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/courses/${courseId}/enrolments/${enrolmentId}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public getEnrolmentActivities: (courseId: number, userId: number) => Promise<void> = (courseId, userId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/courses/${courseId}/users/${userId}/activities?limit=${30}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public getS3SignedUrl: (
    groupId: number,
    fileName: string,
    fileSize: number,
    type: string,
    category: string
  ) => Promise<void> = (groupId, fileName, fileSize, type, category) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`groups/${groupId}/s3/sign`, { category, type, file_size: fileSize, file_name: fileName })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  };

  public startPaidCourse: (
    courseId: number,
    payment_method_id: any
  ) => Promise<void> = (courseId, payment_method_id) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/enrolments`, { payment_method_id })
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  };

  public completeContent: (
    courseId: number,
    contentId: number
  ) => Promise<void> = (courseId, contentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/content/${contentId}/complete`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public uploadImage: (courseId: number, image: any) => Promise<void> = (
    courseId,
    image
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/contents/images`, image)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public getCourse: (groupId: number, courseId: any, query?: string) => Promise<void> = (groupId, courseId, query = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(`/groups/${groupId}/courses/${courseId}${query}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  };

  public getSingleContent: (
    groupId: number,
    courseId: number,
    contentId: number,
    query?: string,
  ) => Promise<void> = (groupId, courseId, contentId, query = '') => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`groups/${groupId}/courses/${courseId}/contents/${contentId}${query}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  };

  public getEnrolledCourses: (
    userId: number,
    groupId: number
  ) => Promise<void> = (userId, groupId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/users/${userId}/groups/${groupId}/courses`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public getEnrollableMembers: (
    courseId: number,
    page: number,
    searchQuery?: string
  ) => Promise<void> = (courseId, page = 1, searchQuery = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`courses/${courseId}/unenrolled-members?page=${page}${searchQuery.length > 0 ? `&search=${searchQuery}` : ""}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public orderContent: (
    courseId: any,
    sectionId: number,
    contents: any
  ) => Promise<void> = (courseId, sectionId, contents) => {
    const orderObject = {
      contents
    };
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(
          `courses/${courseId}/sections/${sectionId}/contents/positions`,
          orderObject
        )
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public orderSections: (courseId: any, sections: any) => Promise<void> = (
    courseId,
    sections
  ) => {
    const orderObject = {
      sections
    };
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`courses/${courseId}/sections/positions`, orderObject)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public addSectionPDFContent: (
    courseId: number,
    sectionId: number,
    pdfObject: any
  ) => Promise<void> = (courseId, sectionId, pdfObject) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/sections/${sectionId}/contents`, pdfObject)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  };

  public updateSectionContentPDF: (
    courseId: number,
    sectionId: number,
    contentId: number,
    pdfObject: any
  ) => Promise<void> = (courseId, sectionId, contentId, pdfObject) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(
          `/courses/${courseId}/sections/${sectionId}/contents/${contentId}`,
          pdfObject
        )
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  };

  public addAfterCopyPDF: (
    courseId: number,
    sectionId: number,
    type: any,
    postBody: any
  ) => Promise<void> = (
    courseId,
    sectionId,
    type,
    postBody
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .post(`/courses/${courseId}/sections/${sectionId}/contents`, {
            type,
            ...postBody
          })
          .then((response: any) => {
            resolve(response);
          }).catch((error: any) => {
            reject(error)
            new Error("Something went wrong!")
          })
      });
    };

  public addSectionTextContent: (
    courseId: number,
    sectionId: number,
    type: any,
    title: any,
    description: any
  ) => Promise<void> = (courseId, sectionId, type, title, description) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/sections/${sectionId}/contents`, {
          type,
          title,
          description
        })
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  };

  public updateSectionContentText: (
    courseId: number,
    sectionId: number,
    contentId: number,
    type: any,
    title: any,
    description: any
  ) => Promise<void> = (
    courseId,
    sectionId,
    contentId,
    type,
    title,
    description
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .put(
            `/courses/${courseId}/sections/${sectionId}/contents/${contentId}`,
            {
              type,
              title,
              description
            }
          )
          .then((response: any) => {
            resolve(response);
          }).catch((error: any) => {
            reject(error)
            new Error("Something went wrong!")
          })
      });
    };

  public addSectionVideoContent: (
    courseId: number,
    sectionId: number,
    type: any,
    title: any,
    description: any,
    content_url: any,
    metaData: any
  ) => Promise<void> = (
    courseId,
    sectionId,
    type,
    title,
    description,
    content_url,
    metaData
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .post(`/courses/${courseId}/sections/${sectionId}/contents`, {
            type,
            title,
            description,
            content_url,
            ...metaData
          })
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            reject(error)
            new Error("Something went wrong!")
          });
      });
    };

  public updateSectionContentTitle: (
    courseId: number,
    sectionId: number,
    contentId: number,
    type: any,
    title: any,
  ) => Promise<void> = (
    courseId,
    sectionId,
    contentId,
    type,
    title,
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .put(
            `/courses/${courseId}/sections/${sectionId}/contents/${contentId}`,
            {
              type,
              title,
            }
          )
          .then((response: any) => {
            resolve(response);
          }).catch((error: any) => {
            reject(error)
            new Error("Something went wrong!")
          });
      });
    };

  public updateSectionContent: (
    courseId: number,
    sectionId: number,
    contentId: number,
    type: any,
    lessonDetails: any,
    fileMeta: any
  ) => Promise<void> = (
    courseId,
    sectionId,
    contentId,
    type,
    lessonDetails,
    fileMeta
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .put(
            `/courses/${courseId}/sections/${sectionId}/contents/${contentId}`,
            {
              type,
              ...lessonDetails,
              ...fileMeta
            }
          )
          .then((response: any) => {
            resolve(response);
          }).catch((error: any) => {
            reject(error)
            new Error("Something went wrong!")
          });
      });
    };

  public getSectionContent: (
    courseId: number,
    sectionId: number,
    userId?: number,
    preview?: boolean,
  ) => Promise<void> = (courseId, sectionId, userId, preview) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/courses/${courseId}/sections/${sectionId}/contents${userId ? `?user_id=${userId}` : ''}${preview ? `?preview=true` : ''}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public deleteSectionContent: (
    sectionId: number,
    contentId: number
  ) => Promise<void> = (sectionId, contentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/sections/${sectionId}/contents/${contentId}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public publishCourse: (courseId: number) => Promise<void> = courseId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/publish`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public unpublishCourse: (courseId: number) => Promise<void> = courseId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/courses/${courseId}/publish`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public updateCoverImage: (
    courseId: number,
    coverImage: any
  ) => Promise<void> = (courseId, coverImage) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/cover-image`, coverImage)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public updateThumbnailImage: (
    courseId: number,
    thumbnailImage: any
  ) => Promise<void> = (courseId, thumbnailImage) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/thumbnail-image`, thumbnailImage)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public getCoursesSections: (courseId: number, userId?: number, preview?: boolean, isAdmin?: boolean) => Promise<void> = (courseId, userId, preview, isAdmin) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/courses/${courseId}/sections${userId ? `?user_id=${userId}` : ''}${preview ? `?preview=true` : ''}${isAdmin ? `?admin=true` : ''}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public getCourseContentTypeCount: (courseId: number) => Promise<void> = courseId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/courses/${courseId}/content-type-summary`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public createCourseSection: (
    courseId: number,
    title: any,
    description: any
  ) => Promise<void> = (courseId, title, description) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/sections`, {
          title,
          description
        })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public updateCourseSection: (
    sectionId: number,
    title: any,
    description: any
  ) => Promise<void> = (sectionId, title, description) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/sections/${sectionId}`, {
          title,
          description
        })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public deleteCourseSection: (
    sectionId: number
  ) => Promise<void> = sectionId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.delete(`/sections/${sectionId}`).then((response: any) => {
        resolve(response);
      }).catch((error: any) => {
        reject(error)
        new Error("Something went wrong!")
      })
    });
  };

  public getCourses: (groupId: number, page?: number) => Promise<void> = (groupId, page = 1) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/courses?page=${page}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public getCourseAuthorizedUsers: (
    courseId: number,
    page?: number,
    query?: string
  ) => Promise<void> = (courseId, page = 1, query = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/courses/${courseId}/authorized-users?page=${page}${query.length > 0 ? `&search=${query}` : ""}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public getCourseNonAuthorizedUsers: (
    groupId: number,
    courseId: number,
    query?: string,
    limit?: number,
    filter?: string
  ) => Promise<void> = (groupId, courseId, query = "", limit = 50, filter: string = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/library-contents/${courseId}/non-authorized-users?content_type=course&search=${encodeURIComponent(
            query
          )}&limit=${limit}${filter.length > 0 ? `&${filter}` : ""}`
        )
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public addCourseAuthorizedUser: (
    courseId: number,
    userId: number
  ) => Promise<void> = (courseId, userId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/authorized-users`, { user_id: userId })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public removeCourseAuthorizedUser: (
    courseId: number,
    userId: number
  ) => Promise<void> = (courseId, userId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/courses/${courseId}/authorized-users`,
          {
            data: {
              user_id: userId
            }
          })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public deleteCourse: (courseId: number) => Promise<void> = courseId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.delete(`/courses/${courseId}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {

          reject(error)
          new Error("Something went wrong!")
        });
    });
  };

  public saveCoursePresenter: (courseId: number, presenter: any) => Promise<void> = (courseId, presenter) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.post(`/courses/${courseId}/presenters`, presenter).then((response: any) => {
        resolve(response);
      }).catch((error: any) => {
        reject(error)
        new Error("Something went wrong!")
      })
    });
  };

  public removeCoursePresenter: (courseId: number, presenter: any) => Promise<void> = (courseId, presenter) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.delete(`/courses/${courseId}/presenters/${presenter}`).then((response: any) => {
        resolve(response);
      }).catch((error: any) => {
        reject(error)
        new Error("Something went wrong!")
      })
    });
  };

  public createCourse: (
    groupId: number,
    formData: any
  ) => Promise<void> = (groupId, formData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/courses`, formData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  };

  public updateCourse: (
    groupId: number,
    courseId: number,
    formData: any,
    fileMeta: any
  ) => Promise<void> = (
    groupId,
    courseId,
    formData,
    fileMeta
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .put(`/groups/${groupId}/courses/${courseId} `, {
            ...formData,
            ...fileMeta
          })
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            reject(error)
            new Error("Something went wrong!")
          })
      });
    };

  public loadMore: (url: string) => Promise<void> = (url) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(url)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public activateCourseSectionDripping: (
    sectionId: number,
  ) => Promise<void> = sectionId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/sections/${sectionId}/activate-drip`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    })
  }

  public deactivateCourseSectionDripping: (
    sectionId: number,
  ) => Promise<void> = sectionId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/sections/${sectionId}/activate-drip`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    })
  }

  public setSectionDripInfo: (
    sectionId: number,
    dripInfo: any
  ) => Promise<void> = (sectionId, dripInfo) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/sections/${sectionId}`, dripInfo)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    })
  }

  public overrideDrip: (
    courseId: number,
    enrolmentId: number,
  ) => Promise<void> = (courseId, enrolmentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/courses/${courseId}/enrolments/${enrolmentId}/override-drip`, {
          override_drip: true,
        })
        .then(() => resolve())
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    })
  }

  public previewDripEmail: (
    groupId: string,
    payload: { subject: string; body: string }
  ) => Promise<void> = (groupId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/custom-emails/test`, payload)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    })
  }

  public uploadLessonAttachments: (
    courseId: number,
    contentId: number,
    files: any,
  ) => Promise<void> = (courseId, contentId, files) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/contents/${contentId}/files`, files)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    })
  }

  public publishCourseLesson: (
    courseId: number,
    lessonId: number
  ) => Promise<void> = (courseId, lessonId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/contents/${lessonId}/publish`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    })
  }

  public unpublishCourseLesson: (
    courseId: number,
    lessonId: number
  ) => Promise<void> = (courseId, lessonId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/courses/${courseId}/contents/${lessonId}/publish`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    })
  }

  public duplicateCourseLesson: (
    courseId: number,
    lessonId: number
  ) => Promise<void> = (courseId, lessonId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/contents/${lessonId}/duplicate`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  }

  public duplicateCourseSection: (
    courseId: number,
    sectionId: number
  ) => Promise<void> = (courseId, sectionId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/sections/${sectionId}/duplicate`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  }

  public duplicateCourse: (
    groupId: number,
    courseId: number,
  ) => Promise<void> = (groupId, courseId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/courses/${courseId}/duplicate`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  }

  public publishCourseSection: (
    courseId: number,
    sectionId: number
  ) => Promise<void> = (courseId, sectionId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/sections/${sectionId}/publish`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  }

  public unpublishCourseSection: (
    courseId: number,
    sectionId: number
  ) => Promise<void> = (courseId, sectionId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/courses/${courseId}/sections/${sectionId}/publish`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  }

  public deleteCourseLessonAttachment: (
    courseId: number,
    lessonId: number,
    fileId: number
  ) => Promise<void> = (courseId, lessonId, fileId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/courses/${courseId}/contents/${lessonId}/files/${fileId}`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  }

  public fetchStudentTaks: (
    groupId: number,
    courseId: number,
    userId?: number
  ) => Promise<void> = (
    groupId, courseId, userId
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .get(`groups/${groupId}/courses/${courseId}/task-lessons${userId ? `?user_id=${userId}` : ''}`)
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            reject(error)
            new Error("Something went wrong!")
          });
      });
    };

  public resetCourseProgress: (
    groupId: number,
    courseId: number,
    enrollmentId: number
  ) => Promise<void> = (
    groupId, courseId, enrollmentId
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .post(`groups/${groupId}/courses/${courseId}/enrolments/${enrollmentId}/reset`)
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            reject(error)
            new Error("Something went wrong!")
          });
      });
    };

  public resetStudentTaskProgress: (
    groupId: number,
    userId: number,
    taskId: number
  ) => Promise<void> = (groupId, userId, taskId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`groups/${groupId}/users/${userId}/course-tasks/${taskId}/reset`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  };

  public getUserQuizResults: (
    groupId: any,
    userId: any,
    quizId: any,
    submissionId?: any
  ) => Promise<void> = (groupId, userId, quizId, submissionId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`groups/${groupId}/users/${userId}/course-contents/${quizId}/submission${submissionId ? `/?submission_id=${submissionId}` : ''}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  };
  public createCourseLessonQuestion: (
    groupId: number,
    lessonId: number,
    questionData: any,
  ) => Promise<void> = (groupId, lessonId, questionData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/course-contents/${lessonId}/questions`, questionData)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  }

  public deleteCourseLessonQuestion: (
    groupId: number,
    lessonId: number,
    questionId: any,
  ) => Promise<void> = (groupId, lessonId, questionId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/course-contents/${lessonId}/questions/${questionId}`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  }

  public getCourseLessonQuestions: (
    groupId: number,
    lessonId: number,
  ) => Promise<void> = (groupId, lessonId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/course-contents/${lessonId}/questions`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  }

  public updateCourseLessonQuestion: (
    groupId: number,
    lessonId: number,
    questionId: number,
    questionData: any,
  ) => Promise<void> = (groupId, lessonId, questionId, questionData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/course-contents/${lessonId}/questions/${questionId}`, questionData)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  }

  public submitCourseLessonQuizAnswers: (
    groupId: number,
    lessonId: number,
    answers: any,
  ) => Promise<void> = (groupId, lessonId, answers) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/course-contents/${lessonId}/submit`, {
          submission: answers
        })
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  }

  public uploadCourseLessonQuestionImage: (
    groupId: number,
    lessonId: number,
    questionId: number,
    imageFormData: any,
  ) => Promise<void> = (groupId, lessonId, questionId, imageFormData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/course-contents/${lessonId}/questions/${questionId}/image-upload`, imageFormData)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    })
  }

  public expireEnrollment: (
    groupId: number,
    courseId: number,
    enrolmentId: number,
  ) => Promise<void> = (groupId, courseId, enrolmentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`groups/${groupId}/courses/${courseId}/enrolments/${enrolmentId}/expire`)
        .then(() => resolve())
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    })
  }

  public activateExpiredEnrollment: (
    groupId: number,
    courseId: number,
    enrolmentId: number,
    payload?: any
  ) => Promise<void> = (groupId, courseId, enrolmentId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`groups/${groupId}/courses/${courseId}/enrolments/${enrolmentId}/activate`, payload)
        .then(() => resolve())
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    })
  }

  public getCoursePosts: (
    groupId: number,
    courseId: number,
  ) => Promise<void> = (groupId, courseId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`groups/${groupId}/courses/${courseId}/posts`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    })
  }

  public getCourseOutline: (
    groupId: number,
    courseId: number,
  ) => Promise<void> = (groupId, courseId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`groups/${groupId}/courses/${courseId}/outline`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  }

  public getCourseOutlineWorkflow: (
    groupId: number,
    courseId: number,
    query?: string,
  ) => Promise<void> = (groupId, courseId, query = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`groups/${groupId}/courses/${courseId}/outline${query}`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  }

  public trackCourseContentProgress: (
    groupId: number,
    courseId: number,
    contentId: number,
    progressDetails: any,
  ) => Promise<void> = (groupId, courseId, contentId, progressDetails) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`groups/${groupId}/courses/${courseId}/contents/${contentId}/track-progress`, progressDetails)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });

  }

  public exportFormLessonResponse: (
    groupId: number,
    contentId: number,
  ) => Promise<void> = (groupId, contentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`groups/${groupId}/course-contents/${contentId}/export-form-response`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  }

  public getLockedLessons: (
    groupId: number,
    courseId: number,
    enrollmentId: number,
  ) => Promise<void> = (groupId, courseId, enrollmentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`groups/${groupId}/courses/${courseId}/enrolments/${enrollmentId}/lockedLessons`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  }

  public unlockLesson: (
    groupId: number,
    courseId: number,
    enrollmentId: number,
    lessonId: number,
  ) => Promise<void> = (groupId, courseId, enrollmentId, lessonId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`groups/${groupId}/courses/${courseId}/enrolments/${enrollmentId}/lessons/${lessonId}/unlock`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  }

  public updateSectionLessionCompletion: (
    sectionId: number,
    payload: any
  ) => Promise<void> = (sectionId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/sections/${sectionId}`, payload)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };
}
